.form-header {
  h1 {
    @extend .tag, .is-primary;
    font-weight: $weight-semibold;
    text-transform: uppercase;
  }

  h2 {
    display: flex;
    align-items: center;
    // flex-direction: column;

    font-size: $size-5;
    font-weight: $weight-semibold;
    margin: 16px 0 60px;
    line-height: 1;

    .tag {
      border: 2px solid $black;
      border-radius: 100%;
      width: 2em;
      font-size: $size-5;
      margin-right: 10px;
    }
  }
}

.form-container {
  padding-bottom: 60px;

  .field {
    &:not(:last-child) {
      margin-bottom: 28px;
    }

    &.has-error {
      .radio-select,
      .input,
      .select select {
        border-color: $red;
      }
    }
  }

  .label {
    font-size: $size-7;
    color: $black;
    margin-bottom: 0.25rem;
    font-weight: $weight-semibold;
  }

  .input {
    &[type='number'] {
      max-width: 220px;
    }
  }

  .help-text {
    font-size: $size-8;
    color: $grey-darker;
    margin: 12px 0 16px;

    &.is-small {
      font-size: $size-8;
      margin: 8px 0 16px;
    }
  }

  .radio-select {
    background: $white;
    border: 1px solid $border;
    border-radius: $radius;
    padding: 16px;

    .radio + .radio {
      margin-left: 0;
      margin-top: 12px;
    }

    .radio {
      display: flex;
      align-items: baseline;

      input {
        margin-right: 8px;
      }
    }
  }

  fieldset {
    & + fieldset {
      margin-top: 60px;
    }

    .fieldset-title {
      border-bottom: 1px solid $grey-darker;
      padding-bottom: 4px;
      margin-bottom: 16px;
      text-transform: uppercase;
      font-size: $size-8;
      font-weight: $weight-medium;
    }

    h3.title {
      color: $black;
      font-size: 1.25rem;
    }

    > .field:not(:first-of-type) {
      margin-top: 32px;
    }

    .field.is-flex {
      margin-bottom: 0;

      .label {
        flex: 1;
        margin-right: 24px;
      }
    }
  }

  footer {
    position: fixed;
    bottom: 0;
    z-index: 10;
    left: 0;
    right: 0;
    background: rgba(200, 200, 200, 0.3);
    backdrop-filter: blur(10px);
    padding: 16px 0;
    border-top: 1px solid $grey-darker;
  }
}
