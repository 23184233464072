@charset "utf-8";

//////////////////////////////////////// COLOURS
$white: #ffffff;
$black: #21242d;
$brown: #5c4033;

$grey-darker: #4c4e55;
$grey-dark: #d8cab8;
$grey: #e4e4e4;
$grey-light: #eeedeb;
$grey-lighter: #f7f7f7;
$red: #ff0000;
$primary: #606452;
$background: $grey-light;
$text: $black;
$link: $primary;

$border: $grey-dark;
$border-hover: $primary;
$hr-background-color: $border;

$custom-colors: (
  'brown': (
    $brown,
    $white,
  ),
);

$gap: 30px;
$block-spacing: 20px;

$radius-small: 4px;
$radius: 8px;
$radius-medium: 16px;
$radius-large: 20px;

$control-height: 50px;
$control-radius: $radius;
$control-padding-horizontal: 1em;
$control-line-height: 1.2;

$tablet: 768px;
$desktop: 1080px;
$widescreen: 1440px;
$fullhd: 1620px;

$size-1: clamp(3rem, 4.3243vw, 4rem); // 48-64px
$size-2: clamp(2.25rem, 3.2432vw, 3rem); // 36-48px
$size-3: clamp(1.5rem, 2.4324vw, 2.125rem); // 24-34px
$size-4: clamp(1.5rem, 2.1621vw, 2rem); // 24-32px
$size-5: 1.5rem; // 24px
$size-6: 1.125rem; // 18px
$size-7: 1rem; // 16px
$size-8: 0.875rem; // 14px
$size-9: 0.75rem; // 12px

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 $size-8 $size-9;
$size-small: $size-7;
$size-normal: $size-6;
$size-medium: $size-5;
$size-large: $size-4;
@import 'bulma/sass/utilities/_all.sass';

//
$family-primary: 'Open Sans', sans-serif;
$body-background-color: $grey-light;
$body-color: $black;
$body-size: 16px;
@import 'bulma/sass/base/_all.sass';

//
$navbar-height: 7rem;
$navbar-item-img-max-height: 3.125rem;
$navbar-item-hover-background-color: transparent;
$navbar-background-color: transparent;
$navbar-burger-color: $white;
@import 'bulma/sass/components/navbar.sass';

//
$card-shadow: none;
$card-content-padding: 1rem 1.25rem 1.25rem;
@import 'bulma/sass/components/card.sass';

//
$section-padding: 0;
$section-padding-desktop: 0;
$footer-padding: 30px;
$footer-background-color: $background;
@import 'bulma/sass/layout/_all.sass';

//
@import 'bulma/sass/elements/box.sass';
@import 'bulma/sass/elements/button.sass';
@import 'bulma/sass/elements/container.sass';
@import 'bulma/sass/elements/content.sass';
@import 'bulma/sass/elements/icon.sass';
@import 'bulma/sass/elements/image.sass';
@import 'bulma/sass/elements/notification.sass';
@import 'bulma/sass/elements/table.sass';
@import 'bulma/sass/elements/tag.sass';

//
// $input-focus-box-shadow-color: $primary;
@import 'bulma/sass/form/_all.sass';

//
$column-gap: 10px;
@import 'bulma/sass/grid/columns.sass';

//
$spacing-values: (
  '0': 0,
  '1': 0.25rem,
  '2': 0.5rem,
  '3': 10px,
  '4': 16px,
  '5': 20px,
  '6': 30px,
  '7': 40px,
  '8': 60px,
  '9': 80px,
  'auto': auto,
);
@import 'bulma/sass/helpers/_all.sass';

// project
@import 'components/form.scss';
@import 'components/layout.scss';
@import 'components/components.scss';
@import './typo.scss';
