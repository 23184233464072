nav.navbar {
  background-image: linear-gradient(#d8cab8, transparent);

  .navbar-brand {
    img {
      height: 32px;

      @include tablet {
        height: auto;
      }
    }

    span.is-logo {
      border: 1px solid $primary;
      color: $primary;
      padding: 3px 5px;

      margin-left: 4px;
      border-radius: $radius-small;
      font-weight: $weight-semibold;

      @include tablet {
        margin-top: 4px;
      }
    }
  }

  @include touch {
    .navbar-menu {
      box-shadow: none;
      border-bottom: 1px solid $brown;
    }
  }
}

.container {
  @include touch {
    padding-left: 15px;
    padding-right: 15px;
  }

  .is-limited {
    margin: 0 auto;

    &.is-600 {
      max-width: 600px;
    }

    &.is-900 {
      max-width: 900px;
    }

    &.is-1200 {
      max-width: 1200px;
    }
  }
}
