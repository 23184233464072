.image {
  .is-cover {
    object-fit: cover;
  }
}

.card {
  display: block;
  border: 1px solid $border;

  .column > & {
    height: 100%;
  }

  &:is(a) {
    &:hover {
      border-color: $border-hover;
    }
  }
}

.table {
  border-radius: $radius;
}

.box {
  border-radius: $radius;

  &.is-emphasis {
    margin: 16px 0 10px;
    // background: $grey;
    // border-radius: 0 $radius $radius 0;
    // border-bottom: 4px solid $dark;
    background: $grey;
    box-shadow: none;

    .tag {
      text-transform: uppercase;
      font-size: $size-8;
      // font-weight: $weight-semibold;
      margin-bottom: 8px;
    }
  }

  &.is-bordered {
    background: $light;
    margin: 16px 0 10px;
    border: 1px solid $grey-darker;
    position: relative;
    z-index: 1;

    & + .box.is-emphasis {
      margin-top: -16px;
      border-radius: 0 0 $radius $radius;
    }
  }
}

#loader {
  position: fixed;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(200, 200, 200, 0.5);
  @extend %overlay;

  &:after {
    @extend %loader;
    height: 4rem;
    width: 4rem;
    border-width: 4px;
    border-left-color: $primary;
    border-bottom-color: $primary;
  }
}

.image img {
  border-radius: $radius;

  &.is-cover {
    object-fit: cover;
  }

  &.is-contain {
    object-fit: contain;
  }
}
