@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

.title {
  hyphens: auto;
  word-wrap: break-word;
  color: $primary;
  font-weight: $weight-semibold;

  &:is(h1) {
    margin-bottom: 3rem;
  }
}

a {
  // color: $primary;
}

// set line-heights / letter-spacings corresponding to sizes
$lh-1: 1.2;
$lh-2: 1.2;
$lh-3: 1.2;
$lh-4: 1.2;
$lh-5: 1.1666666667;
$lh-6: 1.5;
$lh-7: 1.2;
$lh-8: 1.2857142857;
$lh-9: 1.25;
$line-heights: $lh-1 $lh-2 $lh-3 $lh-4 $lh-5 $lh-6 $lh-7 $lh-8 $lh-9;

@for $i from 1 through length($line-heights) {
  $lh: nth($line-heights, $i);

  .is-size-#{$i} {
    line-height: #{$lh};
  }
}
